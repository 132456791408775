<template>
  <div>
    <vs-popup class="sm:popup-w-50" title="Edit Proyek" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <label class="ml-1 text-xs opacity-75">Company *</label>
            <vx-input-group>
              <vs-input placeholder="Pilih Company" v-model="data.company.nama" readonly/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="isModalCompanyActive = true"/>
                </div>
              </template>
            </vx-input-group>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="Kode *" v-model="data.kode"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="Nama *" v-model="data.nama"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="Telp" v-model="data.telp"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="HP" v-model="data.hp"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Fax" v-model="data.fax"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Email *" v-model="data.email"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input type="number" class="w-full" label-placeholder="Luas Proyek *" v-model="data.luas_proyek"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <vs-input class="w-full" label-placeholder="Alamat *" v-model="data.alamat"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <vs-input class="w-full" label-placeholder="Kota" v-model="data.kota"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <label class="ml-1 text-xs opacity-75">Logo</label>
            <vs-input class="w-full" type="file" accept="image/*" v-model="data.logoTemp"
                      @change="data.logo = $event.target.files[0]"/>
          </div>
        </div>

        <vs-divider class="mt-8"></vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col w-1/2">
            <label class="ml-1 text-xs opacity-75">Siteplan *</label>
          </div>
          <div class="vx-col w-1/2">
            <vs-button class="float-right" color="success" type="filled" icon-pack="feather" icon="icon-plus" size="small" @click="addSiteplanForm"/>
          </div>
        </div>
        <vs-table ref="table" :data="data.siteplan">
          <template slot="thead">
            <vs-th>Aksi</vs-th>
            <vs-th>Nama</vs-th>
            <vs-th>File</vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody class="p-0 m-0">
            <vs-tr :data="item" :key="item.id" v-for="(item, index) in data">
              <vs-td>
                <vs-button @click="removeSiteplanForm(index)" color="danger" :type="item.isNew ? 'border' : 'filled'" icon-pack="feather" icon="icon-x" size="small"/>
              </vs-td>
              <vs-td>
                <vs-input class="w-full p-0 m-0" placeholder="Nama *" v-model="item.nama"/>
              </vs-td>
              <vs-td>
                <vs-input class="w-full" type="file" accept="image/*" v-model="item.temp" @change="item.file = $event.target.files[0]"/>
              </vs-td>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modals-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding animate-none"
                title="Pilih Data Company"
                :active="isModalCompanyActive"
                v-on:update:active="isModalCompanyActive = $event">
        <Company :selectable="true" @selected="onSelectedModalCompany"/>
      </vs-popup>
    </vs-popup>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import Company from '@/views/pages/master/company/Company'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  name: 'ProyekEdit',
  components: {
    Company,
    ValidationErrors
  },
  props: ['isActive', 'item'],
  watch: {
    isActive (value) {
      if (value === true) this.data = Object.assign({}, this.item)
      // remove default
      this.data.logo = null
    }
  },
  data () {
    return {
      isModalCompanyActive: false,
      isLoading: false,
      errors: null,
      data: {
        company: {},
        siteplan: []
      },
      siteplanRemoved: []
    }
  },
  methods: {
    addSiteplanForm () {
      const siteplan = {
        id: uuid(),
        temp: '',
        file: '',
        nama: `Siteplan ke-${this.data.siteplan.length + 1}`,
        isNew: true
      }
      this.data.siteplan.push(siteplan)
    },

    removeSiteplanForm (index) {
      const removedSiteplan = this.data.siteplan[index]
      if (!removedSiteplan.isNew) {
        this.siteplanRemoved.push(removedSiteplan.id)
      }
      this.data.siteplan.splice(index, 1)
    },

    onSelectedModalCompany (item) {
      this.data.company = item
      this.isModalCompanyActive = false
    },

    save () {
      this.errors = null
      this.isLoading = true

      // append all data to formData
      const vue = this
      const formData = new FormData()
      formData.append('_method', 'PATCH')
      formData.append('id_company', this.data.company.id || '')
      if (this.data.logo != null) formData.append('logo', this.data.logo || '')

      // siteplan
      _.each(this.data.siteplan, (item, index) => {
        formData.append(`siteplan[${index}][id]`, item.id)
        formData.append(`siteplan[${index}][nama]`, item.nama)
        formData.append(`siteplan[${index}][file]`, item.file ? item.file : '')
        formData.append(`siteplan[${index}][isNew]`, item.isNew ? item.isNew : '')
      })

      // removed siteplan
      _.each(this.siteplanRemoved, (item, index) => {
        formData.append(`siteplanRemoved[${index}]`, item)
      })

      const exceptKeys = ['company', 'siteplan', 'logoTemp', 'id_company']
      Object.keys(vue.data).filter(key => !exceptKeys.includes(key)).forEach(key => {
        const value = vue.data[key]
        value == null ? formData.append(key, '') : formData.append(key, value)
      })

      // send request
      ProyekRepository.update(formData)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.resetData()
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
